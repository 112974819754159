<template>
  <VueContentHolder id="divUsersManualDetailWrapper" :padding="[20, 25]">
    <div v-html="contentHTML"></div
  ></VueContentHolder>
</template>
<script>
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import StorageHelper from '@/utils/storageHelper';

export default {
  name: 'UserManualDetail',
  components: {
    VueContentHolder,
  },
  data() {
    return {
      contentHTML: null,
    };
  },
  created() {
    this.contentHTML = new StorageHelper({
      id: process.env.VUE_APP_STORAGE_USER_MANUAL_CONTENT_KEY,
    }).get();
  },
};
</script>
